<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("pos") + " " + $t("brands.brand") }}</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{ $t("confirmdelete") }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              color="primary"
              @click="downloadExcelTemplateAPI('ID_POS_BRAND')"
              class="mb-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-2 ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefroutes"
              id="posbrands_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";

export default {
  name: "route",
  data: function() {
    return {
      filterlist_fetch_url: "pos/brands/getfilterlist",
      filterable_col_list: ["pos", "brand", "idPosBrand"],
      filterCriteria: {
        pos: {},
        brand: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,

      totaldata: 0,
      data: [],

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "El archivo importado no es válido.",
      vertical: true,
      snackbar_class: "deep-purple",

      dialogDelete: false,
      deleteItem: -1,

      /************************* Loading Spinner Overlay *************************/
      isLoadingSpinner: false
    };
  },
  watch: {},
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brandId() {
      return {
        key: "brandId",
        title: `ID ${this.$t("brands.brand")}`,
        field: "brandId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: this.$t("brands.brand") + " " + this.$t("brands.name"),
        field: "brand",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.brand && row.brand.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_idPosBrand() {
      return {
        key: "idPosBrand",
        title: `ID ${this.$t("pos")} ${this.$t("brands.brand")}`,
        field: "idPosBrand",
        align: "left",
        width: 200
      };
    },
    columns() {
      return [
        this.col_posId,
        this.col_pos,
        this.col_brandId,
        this.col_brand,
        this.col_idPosBrand,
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                {" "}
                {row.brand && (
                  <v-icon
                    medium
                    color="red"
                    class="mr-2"
                    on-click={() => this.onItemRemove(row)}
                  >
                    mdi-delete
                  </v-icon>
                )}
              </div>
            );
          }
        }
      ];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    onItemRemove(item) {
      this.deleteItem = item;
      console.log(this.deleteItem);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItem = null;
      });
    },
    deleteItemConfirm() {
      if (this.deleteItem) {
        this.closeDelete();
        const { id } = this.deleteItem;
        let deleteCriteria = `${id}`;
        ApiService.delete(`pos/brands/${deleteCriteria}`).then(() => {
          logInfo("eliminada");
          this.getDataFromApi();
        });
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "idPosBrand") {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#posbrands_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#posbrands_table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      // this.show();
      this.isLoadingSpinner = true;
      let query_params_string = this.getQueryParams();
      let fetch_url = "pos/brands";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        this.spv_list = resp.spv_list;

        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        // this.close();
        this.isLoadingSpinner = false;
      } catch (error) {
        // this.close();
        this.isLoadingSpinner = false;
      }
    },
    async downloadExcel() {
      // this.show();
      this.isLoadingSpinner = true;
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "pos/brands/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        // this.close();
        this.isLoadingSpinner = false;
      } catch (error) {
        // this.close();
        this.isLoadingSpinner = false;
      }
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelecting = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(
            `pos/brands/uploadexcel`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} imported successfully`;
            this.snackbar = true;
            this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            this.getDataFromApi();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
          this.isLoadingSpinner = false;
        } catch (error) {
          console.log(error);
          this.isSelecting = false;
          this.isLoadingSpinner = false;
        }
      }
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
